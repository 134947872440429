import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
export const frontmatter = {
  title: 'Uploadcare Trust Center',
  description: 'Uploadcare is committed to complying with industry-standard privacy and security measures and all applicable laws and regulations to keep customer and end-user data safe and secure.',
  header: 'Uploadcare Trust Center'
};
export const _frontmatter = {};
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "compliance"
    }}><a parentName="h2" {...{
        "href": "#compliance"
      }}>{`Compliance`}</a></h2>
    <p>{`Uploadcare is committed to complying with industry-standard privacy and security measures and all applicable laws and regulations to keep customer and end-user data safe and secure:`}</p>
    <ul>
      <li parentName="ul">{`SOC 2 Type 2. `}<a parentName="li" {...{
          "href": "https://app.vanta.com/uploadcare/trust/op13vrs0yjx3y91k2m9vr"
        }}>{`Request the report`}</a></li>
      <li parentName="ul">{`HIPAA-compliant environment. `}<a parentName="li" {...{
          "href": "/about/hipaa/"
        }}>{`Request the BAA`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/about/gdpr/"
        }}>{`GDPR`}</a>{` & `}<a parentName="li" {...{
          "href": "/about/ccpa/"
        }}>{`CCPA`}</a></li>
    </ul>
    <h2 {...{
      "id": "security-measures"
    }}><a parentName="h2" {...{
        "href": "#security-measures"
      }}>{`Security measures`}</a></h2>
    <p>{`Learn more about steps we take to strengthen our infrastructure, product, and organizational security:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://app.vanta.com/uploadcare/trust/op13vrs0yjx3y91k2m9vr"
        }}>{`Trust Report`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/products/security/"
        }}>{`Platform Security Features`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.google.com/spreadsheets/d/1e3je-i9aK_4ofo6oYpD-OcNm_1hX3pgw/edit"
        }}>{`Standardized Information Gathering`}</a>{` (SIG Lite) `}</li>
    </ul>
    <h2 {...{
      "id": "contact-us"
    }}><a parentName="h2" {...{
        "href": "#contact-us"
      }}>{`Contact us`}</a></h2>
    <p>{`Do you have any questions? `}<a parentName="p" {...{
        "href": "mailto:trust@uploadcare.com"
      }}>{`Email us`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      